import { useMultipleToggles } from '../../../../../../hooks';

const modalAction = {
  EXIT_GUIDED_ATTENDANCE: 'exitGuidedAttendance',
  NO_KIPER_VOICE: 'noKiperVoice',
  NO_KIPER_VOICE_AGENT: 'noKiperVoiceAgent',
  ACTIVITY_HISTORY: 'activityHistory',
};

const defaultModalState = {
  [modalAction.EXIT_GUIDED_ATTENDANCE]: false,
  [modalAction.NO_KIPER_VOICE]: false,
  [modalAction.NO_KIPER_VOICE_AGENT]: false,
  [modalAction.ACTIVITY_HISTORY]: false,
};

const useControlModals = () => {
  const [modals, toggleModal] = useMultipleToggles(defaultModalState);

  return {
    modalAction,
    modals,
    toggleModal,
  };
};

export default useControlModals;
