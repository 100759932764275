import { useContext } from 'react';
import { GuidedAttendanceContext } from '../store';
import {
  triageIds,
  provideServiceTo,
  authorizationStatus,
  callStatus,
  triageTranslate,
  dwellerValidationStatus,
} from '../constants';

const useGuidedAttendance = () => {
  const {
    contextTree: { tree, currentStep, data, previousStep, historyPath },
    setContextTree,
  } = useContext(GuidedAttendanceContext);

  const isNotEmpty = values => !!values?.name;

  const isNotGuidedTriageToTransientPerson = reason => {
    const guidedTriages = [
      triageIds.serviceProvision,
      triageIds.visits,
      triageIds.residentEntry,
    ];
    return !guidedTriages.includes(reason?.id);
  };

  const isServiceProvider = reason => {
    return reason?.id === triageIds.serviceProvision;
  };

  const isVisitor = reason => {
    return reason?.id === triageIds.visits;
  };

  const isResidentEntry = reason => {
    return reason?.id === triageIds.residentEntry;
  };

  const hasUnity = value => {
    return !!value.unity?.id;
  };

  const hasUnityLegacy = value => {
    return !!value.unity?.value;
  };

  const authorizedAndIsTransientPersonNotRegistred = (
    value,
    enableQuickFaceRegistration,
  ) => {
    const { stepFindRegister } = data;
    const transientNotRegistred = !stepFindRegister?.transientPersonId;
    const authorized = value?.callStatus === callStatus.AUTHORIZED;

    return !enableQuickFaceRegistration && transientNotRegistred && authorized;
  };

  const authorizedAndIsTransientPersonRegistredNoPhoto = (
    value,
    enableQuickFaceRegistration,
  ) => {
    const { stepFindRegister } = data;
    const transientRegistred = !!stepFindRegister?.transientPersonId;
    const transientNoPhoto = !stepFindRegister?.hasRegisteredFace;
    const authorized = value?.callStatus === callStatus.AUTHORIZED;

    return (
      !enableQuickFaceRegistration &&
      transientRegistred &&
      transientNoPhoto &&
      authorized
    );
  };

  const authorizedAndIsTransientPersonRegistredHasPhoto = (
    value,
    enableQuickFaceRegistration,
  ) => {
    const { stepFindRegister } = data;
    const transientRegistred = !!stepFindRegister?.transientPersonId;
    const transientHasPhoto = stepFindRegister?.hasRegisteredFace;
    const authorized = value?.callStatus === callStatus.AUTHORIZED;

    return (
      !enableQuickFaceRegistration &&
      transientRegistred &&
      transientHasPhoto &&
      authorized
    );
  };

  const authorizedAndEnabledQuickFaceRegistration = (
    value,
    enableQuickFaceRegistration,
  ) => {
    const authorized = value?.callStatus === callStatus.AUTHORIZED;
    return enableQuickFaceRegistration && authorized;
  };

  const hasPhotoTaken = values => {
    return values?.photoRegistered;
  };

  const hasCompanyAndName = values => !!values?.name && !!values?.companyName;

  const isUnity = value => {
    return value.choice === provideServiceTo.UNITY;
  };

  const isRequestAuthorization = value => {
    return (
      value.status === authorizationStatus.REQUEST_AUTHORIZATION ||
      value.status === authorizationStatus.UNREGISTERED
    );
  };

  const isGuidedAttendanceFinished = finishGuidedAttendance =>
    finishGuidedAttendance;

  const fallbackRule = () => false;

  const hasTransientPersonName = values => !!values?.name;

  const hasValidatedDweller = values => {
    const hasDweller = values?.dweller?.id;
    const hasDocumentValidated = values?.cpf || values?.phone || values?.rg;
    const validatedStatus =
      values?.dwellerValidationStatus === dwellerValidationStatus.VALID;
    const validatedDweller =
      !!hasDweller && !!hasDocumentValidated && validatedStatus;

    return validatedDweller;
  };

  const hasInvalidatedDweller = values => {
    const invalidated =
      values?.dwellerValidationStatus === dwellerValidationStatus.INVALID;
    return invalidated;
  };

  const isCallStatusAuthorized = value => {
    const authorized = value?.callStatus === callStatus.AUTHORIZED;

    return authorized;
  };

  const rules = {
    isNotEmpty,
    isServiceProvider,
    isNotServiceProvider: isNotGuidedTriageToTransientPerson,
    isVisitor,
    isResidentEntry,
    isNotGuidedTriageToTransientPerson,
    hasUnity,
    hasUnityLegacy,
    isUnity,
    fallbackRule,
    isRequestAuthorization,
    hasCompanyAndName,
    hasTransientPersonName,
    hasPhotoTaken,
    isGuidedAttendanceFinished,
    authorizedAndIsTransientPersonNotRegistred,
    authorizedAndIsTransientPersonRegistredNoPhoto,
    authorizedAndIsTransientPersonRegistredHasPhoto,
    authorizedAndEnabledQuickFaceRegistration,
    hasValidatedDweller,
    hasInvalidatedDweller,
    isCallStatusAuthorized,
  };

  const handleRule = ruleName => {
    if (!(ruleName in rules)) return rules.fallbackRule();
    return rules[ruleName];
  };

  const getCurrentStep = (treeInitialStep, currentStepId, path) => {
    const stack = [treeInitialStep];
    const newPath = [...path];
    const prevStepName = newPath.pop();

    if (treeInitialStep?.id === currentStepId) {
      setContextTree({ previousStep: null });
    }
    while (stack.length > 0) {
      const step = stack.pop();

      if (step.id === currentStepId) {
        return step;
      }

      if (step.actions) {
        // const reorderedArray = step.actions?.reverse();
        for (const curr of step.actions) {
          if (curr.nextAttendanceStepId !== null) {
            stack.push(curr.nextAttendanceStep);
          }
        }
      }

      if (step?.componentName.toLowerCase() === prevStepName.toLowerCase()) {
        setContextTree({ previousStep: step });
      }
    }
    return treeInitialStep;
  };

  const handleNextStep = (action, values) => {
    const path = Object.keys(values);
    setContextTree({
      currentStep: action?.nextAttendanceStep,
      previousStep: currentStep,
      previousData: data,
      historyPath: path,
    });
  };

  const handlePreviousStep = (values, setValues) => {
    const historyPathWithoutLastItem = historyPath.slice(0, -1);

    const step = getCurrentStep(
      tree.attendanceStep,
      previousStep?.id,
      historyPathWithoutLastItem,
    );

    const newValues = { ...values };

    const fieldNameCurrentStep =
      currentStep?.componentName?.charAt(0)?.toLowerCase() +
      currentStep?.componentName?.slice(1);

    if (newValues[fieldNameCurrentStep]) {
      delete newValues[fieldNameCurrentStep];
    }

    setValues(newValues);

    setContextTree({
      currentStep: step,
      historyPath: historyPathWithoutLastItem,
    });
  };

  const handleNextAction = ({ values, fieldValue, featureFlag = false }) => {
    const currentAction = currentStep?.actions?.find(action => {
      const ruleFn = handleRule(action?.rule);

      if (typeof ruleFn === 'string') {
        return false;
      }

      return ruleFn(values[fieldValue], featureFlag);
    });

    setContextTree({
      data: { ...values },
    });
    return currentAction;
  };

  const handleGetTriageSelected = values => {
    const triageId = values?.stepAttendanceGetReason?.id;
    const triage = triageTranslate[triageId] || 'service-provision';

    return triage;
  };

  return {
    handleRule,
    getCurrentStep,
    handleNextStep,
    handlePreviousStep,
    handleNextAction,
    handleGetTriageSelected,
  };
};

export default useGuidedAttendance;
