import { useEffect, useContext } from 'react';
import propTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { useTranslation } from 'react-i18next';
import { Category as CategoryItem } from './Item/index';
import { HorizontalScroll } from '../../../../HorizontalScroll/index';
import { CondominiumRulesContext } from '../../CondominiumRules';
import {
  useCurrentLoggedContext,
  useRemoteConfig,
} from '../../../../../hooks/index';
import { TriageContext } from '../../../../../store/index';
import { CategoryFilterWrapper } from './styles';

// Parceiro Belo horizonte, condominio MILLENNIAL
const CONDOMINIUM_CONTEXT_TO_CHANGE_CATEGORIES_NAME = 110306; 

const PREFIX = category => `details.how-treat.category-change-name.${category}`;

const Category = ({ enabledFilters, event }) => {
  const { loggedContext } = useCurrentLoggedContext();
  const { t } = useTranslation('event_attendance'); 
  const {
    categoriesFilter,
    setCategoriesFilter,
    categories,
    setCategories,
  } = useContext(CondominiumRulesContext);

  const { triageContext } = useContext(TriageContext);

  const condominiumPersonContextId =
    event?.condominium?.personContext?.id ||
    event?.condominium?.personContextId;

  const changeCategoriesName = categoriesList => {
    const nameMapping = {
      '1': t(PREFIX('all-sports')),
      '2': t(PREFIX('commercial')),
      '3': t(PREFIX('residential')),
      '4': t(PREFIX('porter')),
    };

    return categoriesList?.map(cat => ({
      ...cat,
      name: nameMapping[cat?.id] || cat?.name,
    }));
  };

  const [getCondominiumRulesCategories, { loading }] = useLazyQuery(
    queries.condominiumRulesCategories,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ condominiumRulesCategories }) => {
        let categoriesList = condominiumRulesCategories;
        if (
          condominiumPersonContextId ===
          CONDOMINIUM_CONTEXT_TO_CHANGE_CATEGORIES_NAME
        ) {
          categoriesList = changeCategoriesName(categoriesList);
        }
        setCategories(categoriesList);
      },
    },
  );

  const showTriageFilterRules = useRemoteConfig({
    path: 'attendance.triageFilterRules',
    condominium: Number(condominiumPersonContextId),
    user: Number(loggedContext?.personContextId),
  });

  useEffect(() => {
    if (condominiumPersonContextId) getCondominiumRulesCategories();
    return () => {
      setCategoriesFilter([]);
    };
  }, [condominiumPersonContextId]);

  useEffect(() => {
    if (showTriageFilterRules && categories.length) {
      const triageFilterCategories = categories.filter(cat =>
        triageContext.filterRulesIds.includes(+cat.id),
      );

      setCategoriesFilter(triageFilterCategories);
    }
  }, [categories, triageContext.filterRulesIds, triageContext.filterText]);

  const isCategorySelected = id =>
    !!categoriesFilter?.find(cat => cat.id === id) || false;

  return (
    !loading &&
    enabledFilters && (
      <CategoryFilterWrapper>
        <HorizontalScroll>
          {categories.length &&
            categories.map(cat => (
              <CategoryItem
                itemId={cat.id}
                label={cat.name}
                key={cat.id}
                onClick={() =>
                  setCategoriesFilter(cat, isCategorySelected(cat.id))
                }
                selected={isCategorySelected(cat.id)}
              />
            ))}
        </HorizontalScroll>
      </CategoryFilterWrapper>
    )
  );
};

export default Category;

Category.propTypes = {
  event: propTypes.object,
  enabledFilters: propTypes.bool.isRequired,
};

Category.defaultProps = {
  event: null,
};
