import { Flex } from '@kiper/ui';
import styled from 'styled-components';

export const AttendanceAssetsContainer = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: max-content;

  @media screen and (min-width: 1920px) {
    gap: 16px;
  }
`;

export const GridContainer = styled.div`
  flex: 1;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
`;

export const MapWrapper = styled(Flex)`
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: center;
`;
