import { useMemo, useEffect, useState, useContext, useCallback } from 'react';
import propTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToggle, useSwal } from '@kiper/hooks';
import { Loader, withErrorBoundary } from '@kiper/ui';
import { event as eventsGql } from '@kiper/monitoring-graphql';
import { apolloErrorHandler } from '@kiper/fns';
import {
  UsersModal,
  EventTimelineModal,
  IotModal,
  EventActivityModal,
} from '../../../components/Modals';
import Popup from '../../../components/Popup';
import {
  useAlert,
  useCurrentLoggedContext,
  useAttendanceRemoteConfig,
} from '../../../hooks';
import attendanceContext from '../attendanceContext';
import { sendAmplitudeData } from '../../../services/amplitude';
import { QuickMenuV2 } from './QuickMenu';
import AttendanceDetailV2 from './AttendanceDetailV2';
import AttendanceDetail from './AttendanceDetail';
import { eventTypes } from '../../../constants';
import { EventAttendanceContext, TriageContext } from '../../../store';
import AttendanceFallbackErrorBoundary from '../../../components/AttendanceFallbackErrorBoundary';
import LockerHistoryModal from '../../../components/Modals/LockerHistoryModal';

const transformContextObject = (context, isFallback, error) => {
  const result = {
    attendanceList: {
      inAttendance: isFallback
        ? context.eventInAttendance
        : context.attendanceList.inAttendance,
      inAttendanceOnHold: isFallback
        ? context.eventOnHoldInAttendance
        : context.attendanceList.inAttendanceOnHold,
      error: isFallback ? error : context.attendanceList.error,
      allEvents: isFallback ? context.events : context.attendanceList.allEvents,
      events: isFallback ? context.eventQueue : context.attendanceList.events,
      resetFilters: isFallback ? () => {} : context.attendanceList.resetFilters,
    },
    finish: {
      finishEvent: context.finish.finishEvent,
      loadingFinish: context.finish.loading,
    },
    stop: {
      dropEvent: context.stop.dropEvent,
    },
    play: {
      getEvent: context.play.getEvent,
    },
    initLoader: context.loading,
    popupData: context.popupData,
    hidePopup: isFallback ? context.handleClearPopupData : context.hidePopup,
    activeTab: context?.tabControl?.activeTab,
    setActiveTab: context?.tabControl?.setActiveTab,
    TAB_IDS: context?.tabControl?.TAB_IDS,
  };

  return result;
};

const EventAttendanceDetail = ({ match, socketError }) => {
  const { hasAlertVisible } = useAlert();
  const { toast } = useSwal();
  const [t] = useTranslation('event_attendance');
  const history = useHistory();
  const { handleClear } = useContext(TriageContext);

  const isFallback = match.path.includes('fallback');

  const eventId = match.params.id;

  const { loggedContext } = useCurrentLoggedContext();

  const { enableEventAutomaticCatch, showEventV2 } = useAttendanceRemoteConfig(
    loggedContext,
  );

  const context = isFallback
    ? useContext(EventAttendanceContext)
    : useContext(attendanceContext);

  const {
    attendanceList: {
      inAttendance,
      inAttendanceOnHold,
      error,
      allEvents,
      events,
      resetFilters,
    },
    finish: { finishEvent, loadingFinish },
    stop: { dropEvent },
    play: { getEvent },
    initLoader,
    popupData,
    hidePopup,
    activeTab,
    TAB_IDS,
  } = transformContextObject(context, isFallback, socketError);

  const [fetchEvent, { data, loading }] = useLazyQuery(eventsGql.getSingleV2, {
    fetchPolicy: 'no-cache',
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      }
    },
    onCompleted: res => {
      sendAmplitudeData('start attendance', {
        partner: res?.eventSingleV2?.partner?.email,
        condominium: res?.eventSingleV2?.condominium?.name,
        eventCode: res?.eventSingleV2?.eventType,
        eventId: res?.eventSingleV2?.eventId,
      });
    },
  });

  const [fetchCameras, { data: newCameras }] = useLazyQuery(
    eventsGql.getSingleCameras,
    {
      fetchPolicy: 'no-cache',
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length) {
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        }
      },
    },
  );

  useEffect(() => {
    Promise.all([
      fetchEvent({ variables: { eventId } }),
      fetchCameras({ variables: { eventId } }),
    ]);
  }, [eventId]);

  useEffect(() => {
    if (!error && !inAttendance && !inAttendanceOnHold && !initLoader)
      history.push('/attendance');
  }, [initLoader, inAttendance, inAttendanceOnHold, error]);

  const [profilePopoverVisible, setProfilePopoverVisible] = useState(null);
  const [usersModalOpen, toggleUsersModalOpen] = useToggle(false);
  const [iotModalOpen, toggleIotModalOpen] = useToggle(false);
  const [eventTimelineModal, toggleEventTimelineModalOpen] = useToggle(false);
  const [eventActivityModal, toggleEventActivityModalOpen] = useToggle(false);
  const [lockerHistoryModal, toggleLockerHistoryModalOpen] = useToggle(false);

  const event = useMemo(() => data?.eventSingleV2 ?? {}, [data]);

  const eventCameras = useMemo(() => newCameras?.eventSingleCameras ?? [], [
    newCameras,
  ]);

  const getNextEvent = useCallback(async () => {
    // events: eventos da fila principal, porém se tiver algum filtro por condomínio, essa fila é substituida pela quantidade de evento do mesmo condomínio
    // allEvents: eventos de todas as filas
    const eventMainQueue = events?.length
      ? events
      : allEvents?.filter(evt => !evt?.onHold && !evt?.onTechnicalReview);

    const isTechnicalReviewQueue =
      activeTab === TAB_IDS.ON_TECHNICAL_REVIEW_QUEUE;

    if (eventMainQueue?.length && !isTechnicalReviewQueue) {
      for (let i = 0; i < eventMainQueue?.length; i += 1) {
        const eventWithAttendant = eventMainQueue[i].attendant;

        const ableToGetNextEventMainQueue = !eventWithAttendant;

        if (ableToGetNextEventMainQueue) {
          try {
            await getEvent(eventMainQueue[i].eventId); // eslint-disable-line
            break;
          } catch (err) {
            sendAmplitudeData('exception getNextEvent', {
              eventId: eventMainQueue[i].eventId,
              error: err,
            });
          }
        }
      }
    }
  }, [events]);

  const onSubmit = values => {
    const hasReportCache = localStorage.getItem(event.eventId);
    if (hasReportCache) {
      localStorage.removeItem(event.eventId);
    }
    const finishObject = {
      message: values.report,
      untreatedEventOptionId: values?.untreatedEvent?.id,
    };
    finishEvent(finishObject)
      .then(
        () =>
          enableEventAutomaticCatch &&
          event.eventType !== eventTypes.attendanceAdditional &&
          event.eventType !== eventTypes.answeredCall &&
          getNextEvent(),
      )
      .then(() => {
        handleClear();
        sendAmplitudeData('finish attendance', {
          partner: event?.partner?.email,
          condominium: event?.condominium?.name,
          eventCode: event?.eventType,
          eventId: event?.eventId,
          reportMessage: values.report,
        });
      });
  };

  const formik = useFormik({
    initialValues: { report: '', untreatedEvent: '' },
    validationSchema: yup.object({
      report: yup
        .string()
        .trim()
        .required(t('details.event-logger.feedback-required-report'))
        .min(5, t('details.event-logger.feedback-required-report'))
        .max(8000, t('event-log-limit')),
      untreatedEvent: yup.object({
        id: yup.number(),
        description: yup.string(),
      }),
    }),
    onSubmit,
  });

  const isInAttendance = useMemo(() => {
    if (!event) return false;
    return (
      (inAttendance && inAttendance.eventId === eventId) ||
      (inAttendanceOnHold && inAttendanceOnHold.eventId === eventId)
    );
  }, [event, eventId, inAttendance, inAttendanceOnHold]);

  const handleToggleUserPopover = userId =>
    setProfilePopoverVisible(oldId => (userId === oldId ? null : userId));

  if (!eventId && !!event) return <></>;

  if (loading) return <Loader fullHeight />;

  const handlePopupAction = async () => {
    resetFilters();
    await getEvent(popupData.eventId);
    hidePopup();
  };

  return (
    <>
      {usersModalOpen && (
        <UsersModal
          formik={formik}
          event={event}
          t={t}
          title={t('details.fast-actions.modal.users.title')}
          onToggle={toggleUsersModalOpen}
        />
      )}

      {iotModalOpen && (
        <IotModal
          event={event}
          t={t}
          sourceNodeId={event?.condominium?.personContext?.treeNodeId}
          onToggle={toggleIotModalOpen}
        />
      )}

      {eventTimelineModal && (
        <EventTimelineModal
          eventId={event.eventId}
          onToggle={toggleEventTimelineModalOpen}
        />
      )}

      {eventActivityModal && (
        <EventActivityModal
          eventId={event.eventId}
          eventDate={event.creationDate}
          onToggle={toggleEventActivityModalOpen}
        />
      )}

      {lockerHistoryModal && (
        <LockerHistoryModal
          condominiumPersonContextId={event?.condominium?.personContext?.id}
          onToggle={toggleLockerHistoryModalOpen}
        />
      )}

      {Object.keys(event).length > 0 &&
        (showEventV2 ? (
          <AttendanceDetailV2
            formik={formik}
            event={event}
            eventCameras={eventCameras}
            hasAlertVisible={hasAlertVisible}
            profilePopoverVisible={profilePopoverVisible}
            toggleEventTimelineModalOpen={toggleEventTimelineModalOpen}
            toggleLockerHistoryModalOpen={toggleLockerHistoryModalOpen}
            usersModalOpen={usersModalOpen}
            toggleIotModalOpen={toggleIotModalOpen}
            toggleEventActivityModalOpen={toggleEventActivityModalOpen}
            handleToggleUserPopover={handleToggleUserPopover}
            isInAttendance={!!isInAttendance}
            inAttendanceOnHold={!!inAttendanceOnHold}
            dropEvent={dropEvent}
            loadingFinish={loadingFinish}
            t={t}
          />
        ) : (
          <AttendanceDetail
            formik={formik}
            event={event}
            eventCameras={eventCameras}
            hasAlertVisible={hasAlertVisible}
            profilePopoverVisible={profilePopoverVisible}
            toggleEventTimelineModalOpen={toggleEventTimelineModalOpen}
            toggleLockerHistoryModalOpen={toggleLockerHistoryModalOpen}
            toggleUsersModalOpen={toggleUsersModalOpen}
            usersModalOpen={usersModalOpen}
            toggleIotModalOpen={toggleIotModalOpen}
            toggleEventActivityModalOpen={toggleEventActivityModalOpen}
            handleToggleUserPopover={handleToggleUserPopover}
            isInAttendance={!!isInAttendance}
            inAttendanceOnHold={!!inAttendanceOnHold}
            dropEvent={dropEvent}
            loadingFinish={loadingFinish}
            t={t}
          />
        ))}

      {popupData.visible && popupData.condoName && (
        <>
          {popupData.inAttendance ? (
            <Popup
              title={t('answered-call')}
              subtitle={popupData.condoName}
              onAction={handlePopupAction}
              buttonText={t('go-to-event')}
              options={{
                visible: popupData.visible,
                hide: () => hidePopup(),
              }}
            />
          ) : (
            <Popup
              legacy
              title={t('answered-call')}
              subtitle={popupData.condoName}
              onAction={handlePopupAction}
              options={{
                visible: popupData.visible,
                delay: 5000,
                hide: () => hidePopup(),
              }}
            />
          )}
        </>
      )}
      {Object.keys(event).length > 0 && (
        <QuickMenuV2 condominium={event?.condominium} event={event} />
      )}
    </>
  );
};

EventAttendanceDetail.propTypes = {
  match: propTypes.object.isRequired,
  socketError: propTypes.object,
};

EventAttendanceDetail.defaultProps = {
  socketError: null,
};

const EventAttendanceDetailComponent = withErrorBoundary(
  EventAttendanceDetail,
  {
    CustomErrorComponent: props => (
      <AttendanceFallbackErrorBoundary backToRoute="/attendance" {...props} />
    ),
  },
);

export default EventAttendanceDetailComponent;
