import propTypes from 'prop-types';
import { FiNavigation, FiPhone } from 'react-icons/fi';
import { Button, Flex } from '@kiper/ui';
import {
  EventActivityModal,
  ExitGuidedAttendanceModal,
  NoKiperVoiceAgentModal,
  NoKiperVoiceModal,
} from '../../../../../../components/Modals';
import { useControlModals, useActionButtons } from '../hooks';

const ActionButtons = ({ event, t }) => {
  const { modalAction, modals, toggleModal } = useControlModals();

  const {
    deviceExtension,
    resolutionIsHigherThanHD,
    handleClickToCall,
    loadingCall,
  } = useActionButtons(event, t, toggleModal, modalAction);

  return (
    <Flex gridGap="8px">
      {deviceExtension && (
        <Button
          variant="out"
          color="primary"
          size="sm"
          title={event?.device?.name}
          icon={<FiPhone />}
          onClick={handleClickToCall}
          disabled={loadingCall}
        >
          {resolutionIsHigherThanHD && t('header.extension')}
        </Button>
      )}

      <Button
        variant="out"
        color="neutral"
        size="sm"
        title={t('header.activity-history')}
        icon={<FiNavigation />}
        onClick={() => toggleModal(modalAction.ACTIVITY_HISTORY)}
      />

      <Button
        variant="out"
        color="secondary"
        size="sm"
        title={t('header.exit-guided-attendance')}
        onClick={() => toggleModal(modalAction.EXIT_GUIDED_ATTENDANCE)}
      >
        {resolutionIsHigherThanHD
          ? t('header.exit-guided-attendance')
          : t('header.exit')}
      </Button>

      {modals[modalAction.EXIT_GUIDED_ATTENDANCE] && (
        <ExitGuidedAttendanceModal
          open
          onToggle={() => toggleModal(modalAction.EXIT_GUIDED_ATTENDANCE)}
        />
      )}

      {modals[modalAction.NO_KIPER_VOICE_AGENT] && (
        <NoKiperVoiceAgentModal
          open
          onToggle={() => toggleModal(modalAction.NO_KIPER_VOICE_AGENT)}
        />
      )}

      {modals[modalAction.NO_KIPER_VOICE] && (
        <NoKiperVoiceModal
          open
          onToggle={() => toggleModal(modalAction.NO_KIPER_VOICE)}
        />
      )}

      {modals[modalAction.ACTIVITY_HISTORY] && (
        <EventActivityModal
          eventId={event.eventId}
          eventDate={event.creationDate}
          onToggle={() => toggleModal(modalAction.ACTIVITY_HISTORY)}
        />
      )}
    </Flex>
  );
};

export default ActionButtons;

ActionButtons.propTypes = {
  event: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
};
