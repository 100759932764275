import { useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge } from '@kiper/ui';
import { MdOutlineDoorBack, MdOutlinePlace } from 'react-icons/md';
import { GuidedAttendanceContext } from '../../../../../../store';
import { useGuidedAttendance } from '../../../../../../hooks';
import {
  dwellerValidationStatus,
  provideServiceTo,
} from '../../../../../../constants';
import * as S from './styles';

const PREFIX = `attendance-information`;

const AttendanceInfo = ({ event }) => {
  const {
    contextTree: { data },
  } = useContext(GuidedAttendanceContext);
  const { handleGetTriageSelected } = useGuidedAttendance();

  const { t } = useTranslation('guided-attendance');

  const groupLabel = t(`${PREFIX}.labels.unity-group`);
  const unityLabel = t(`${PREFIX}.labels.unity`);
  const guULabel = `${groupLabel} - ${unityLabel}`;

  const guUValue = useMemo(() => {
    const unityGroup =
      data?.stepAttendanceGetUnityLegacy?.unityGroup ||
      data?.stepAttendanceGetUnity?.unityGroup?.description ||
      data?.stepGetOnlyUnity?.unityGroup;

    const unity =
      data?.stepAttendanceGetUnityLegacy?.unity?.label ||
      data?.stepAttendanceGetUnity?.unity?.description ||
      data?.stepGetOnlyUnity?.unity?.label;

    if (!unityGroup && !unity) return null;

    return `${unityGroup} - ${unity}`;
  }, [
    data?.stepAttendanceGetUnityLegacy,
    data?.stepAttendanceGetUnity,
    data?.stepGetOnlyUnity,
  ]);

  const placeSerializedParams = useMemo(
    () =>
      event?.place?.serializedParams
        ? JSON.parse(event.place.serializedParams)
        : null,
    [event],
  );

  const handleGetType = stepUnityOrCondo => {
    switch (stepUnityOrCondo?.choice) {
      case provideServiceTo.UNITY:
        return {
          label: t(`${PREFIX}.labels.type`),
          value: t(
            `${PREFIX}.values.${handleGetTriageSelected(data)}-to-unity`,
          ),
        };
      case provideServiceTo.CONDOMINIUM:
        return {
          label: t(`${PREFIX}.labels.type`),
          value: t(
            `${PREFIX}.values.${handleGetTriageSelected(data)}-to-condominium`,
          ),
        };
      default:
        return null;
    }
  };

  const handleGetConfirmation = stepDwellerValidation => {
    const dwellerValidated =
      stepDwellerValidation?.dwellerValidationStatus ===
      dwellerValidationStatus.VALID;

    const dwellerInvalidated =
      stepDwellerValidation?.dwellerValidationStatus ===
      dwellerValidationStatus.INVALID;

    const documentValidated = stepDwellerValidation?.dweller?.documents?.find(
      document => stepDwellerValidation[document.type] === document.value,
    );

    if (dwellerValidated && !!documentValidated) {
      return {
        label: t(`${PREFIX}.labels.document-validation`),
        value: t(`${PREFIX}.values.document-validated`, {
          documentType: t(`${PREFIX}.documents.${documentValidated.type}`),
        }),
      };
    }
    if (dwellerInvalidated) {
      return {
        label: t(`${PREFIX}.labels.document-validation`),
        value: t(`${PREFIX}.values.document-not-informed`),
      };
    }

    return null;
  };

  const handleGetTypeOrConfirmation = values => {
    const stepUnityOrCondo = values?.stepUnityOrCondo;
    const stepDwellerValidation = values?.stepDwellerValidation;

    const type = handleGetType(stepUnityOrCondo);
    const validation = handleGetConfirmation(stepDwellerValidation);

    return (
      type || validation || { label: t(`${PREFIX}.labels.type`), value: null }
    );
  };

  const attendanceInfoData = useMemo(
    () => [
      {
        label: t(`${PREFIX}.labels.name-informed`),
        value: data?.stepAttendanceGetName?.name,
      },
      {
        label: t(`${PREFIX}.labels.reason-attendance`),
        value: data?.stepAttendanceGetReason?.description,
      },
      {
        label: guULabel,
        value: guUValue,
      },
      handleGetTypeOrConfirmation(data),
    ],
    [data],
  );

  return (
    <S.Wrapper>
      <S.InfoTitle>{t('attendance-information.title')}</S.InfoTitle>
      <S.Divider />
      <S.InfoDataWrapper>
        {attendanceInfoData.map(infoDataItem => (
          <S.InfoDataItem key={infoDataItem.label}>
            <S.LabelText>{infoDataItem.label}</S.LabelText>
            <S.ValueText title={infoDataItem.value}>
              {infoDataItem.value || '----'}
            </S.ValueText>
          </S.InfoDataItem>
        ))}
        <S.InfoDataItem>
          <S.LabelText>{t(`${PREFIX}.labels.call-location`)}</S.LabelText>
          {event?.place?.name && (
            <Badge color="secondary50" bordercolor="secondary300">
              <MdOutlinePlace size={14} />
              <S.ValueText title={event?.place?.name}>
                {event?.place?.name}
              </S.ValueText>
            </Badge>
          )}
        </S.InfoDataItem>
        <S.InfoDataItem>
          <S.LabelText>{t(`${PREFIX}.labels.door-type`)}</S.LabelText>
          {placeSerializedParams && (
            <Badge color="secondary300" bordercolor="secondary300">
              <MdOutlineDoorBack size={14} />
              {placeSerializedParams?.external ? (
                <S.ValueText title={t(`${PREFIX}.values.door-external`)}>
                  {t(`${PREFIX}.values.door-external`)}
                </S.ValueText>
              ) : (
                <S.ValueText title={t(`${PREFIX}.values.door-internal`)}>
                  {t(`${PREFIX}.values.door-internal`)}
                </S.ValueText>
              )}
            </Badge>
          )}
        </S.InfoDataItem>
      </S.InfoDataWrapper>
    </S.Wrapper>
  );
};

export default AttendanceInfo;

AttendanceInfo.propTypes = {
  event: propTypes.object.isRequired,
};
