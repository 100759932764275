import { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import * as UI from '@kiper/ui';
import { event as query } from '@kiper/monitoring-graphql';
import { FiBook, FiFileText } from 'react-icons/fi';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import useRemoteConfig from '../../../../hooks/useRemoteConfig';
import { useCurrentLoggedContext } from '../../../../hooks';
import { sendAmplitudeData } from '../../../../services/amplitude';
import {
  CondominiumRules,
  CondominiumNotes,
  ContinuousAttendance,
  HowAct,
} from '../../../../components/QuickMenuParts';
import * as S from './styles';

function QuickMenuV2({ condominium, event }) {
  const { loggedContext } = useCurrentLoggedContext();
  const [registerAccessAmplitude, setRegisterAccessAmplitude] = useState(false);
  const history = useHistory();

  const isGuidedAttendance =
    history.location.pathname.includes('guided-attendance') &&
    !history.location.pathname.includes('fallback');

  const condominiumPersonContextId =
    condominium?.personContext?.id || condominium?.personContextId;

  const showCondominiumRules = useRemoteConfig({
    path: 'attendance.condominiumRules',
    condominium: Number(condominiumPersonContextId),
    user: Number(loggedContext?.personContextId),
  });

  const showContinuousAttendances = useRemoteConfig({
    path: 'attendance.continuousAttendances',
    condominium: Number(condominiumPersonContextId),
    partner: Number(loggedContext?.partner?.personContextId),
    user: Number(loggedContext?.personContextId),
  });

  const condoRuleActivated = useMemo(
    () =>
      condominium?.personContext?.params?.some(
        param => param.name === 'condominiumRule' && param.value?.activated,
      ) || isGuidedAttendance,
    [condominium],
  );

  const [selected, setSelected] = useState(
    showCondominiumRules && condoRuleActivated ? 'how-act' : 'condo-notes',
  );

  const { data, loading } = useQuery(query.eventTypeTreatmentMenu, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (!loading && data?.eventTypeTreatmentMenu?.length) {
      const eventMenu = data.eventTypeTreatmentMenu.find(
        eventTreatmentMenu => eventTreatmentMenu.eventType === event?.eventType,
      );
      if (eventMenu) {
        setSelected(eventMenu.fieldName);
      }
    }
  }, [data]);

  const { condoRules, condoNotes, howAct } = useMemo(
    () => ({
      condoRules: selected === 'condo-rules',
      condoNotes: selected === 'condo-notes',
      howAct: selected === 'how-act',
    }),
    [selected],
  );

  useEffect(() => {
    if (registerAccessAmplitude) {
      sendAmplitudeData(`quick menu select option`, {
        pages: selected,
        condominium: Number(condominiumPersonContextId),
        user: Number(loggedContext?.personContextId),
      });
    } else {
      setRegisterAccessAmplitude(true);
    }
  }, [condoRules, condoNotes, howAct]);

  const handleSelect = value => {
    setSelected(value);
  };

  const quickMenuItemsSettings = [
    {
      icon: <FiBook size={24} />,
      value: 'condo-rules',
    },
    {
      icon: <FiFileText size={24} />,
      value: 'how-act',
    },
  ];

  const ToolItems = quickMenuItemsSettings.map(item => (
    <UI.SplitSidebar.ToolItem
      onSelect={handleSelect}
      active={selected === item.value}
      value={item.value}
      key={item.value}
    >
      {item.icon}
    </UI.SplitSidebar.ToolItem>
  ));

  const showCondoRules =
    showCondominiumRules && condoRules && condoRuleActivated;

  return (
    <UI.SplitSidebar toolItems={ToolItems}>
      <>
        <UI.SplitSidebar.SplitContentPane>
          <>
            {showCondoRules && (
              <CondominiumRules
                condominiumPersonContextId={condominium?.personContext?.id}
                condominiumName={condominium?.name}
                event={event}
              />
            )}
            {condoNotes && !condoRuleActivated && (
              <CondominiumNotes condominium={condominium} />
            )}
            {howAct && (
              <HowAct
                condominiumPersonContextId={condominium?.personContext?.id}
                eventType={event?.eventType}
              />
            )}
          </>
        </UI.SplitSidebar.SplitContentPane>
        {showContinuousAttendances && showCondoRules && (
          <>
            <UI.SplitSidebar.Divider />
            <S.DynamicPaneSizeContainer>
              <UI.SplitSidebar.SplitContentPane flex={0} height="100%" noScroll>
                <ContinuousAttendance
                  attendances={event?.attendances}
                  condominiumPersonContextId={
                    event?.condominium?.personContext?.id
                  }
                  eventId={event?.eventId}
                />
              </UI.SplitSidebar.SplitContentPane>
            </S.DynamicPaneSizeContainer>
          </>
        )}
      </>
    </UI.SplitSidebar>
  );
}

export default QuickMenuV2;

QuickMenuV2.propTypes = {
  event: propTypes.object,
  condominium: propTypes.shape({
    personContext: propTypes.shape({
      id: propTypes.number,
      params: propTypes.array,
    }),
    personContextId: propTypes.number,
    name: propTypes.string,
  }),
};

QuickMenuV2.defaultProps = {
  condominium: null,
  event: {},
};
