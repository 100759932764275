import { useState, useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useToggle } from '@kiper/hooks';
import { Flex, Text } from '@kiper/ui';
import { RiDoorClosedLine } from 'react-icons/ri';
import { FiUnlock, FiVolume2 } from 'react-icons/fi';
import CommandsDevice from '../../../../components/CommandsDevice/CommandsDevice';
import { DEVICE_ACTIONS } from '../../../../hooks/useDevices';
import {
  destinationTypes,
  deviceIdTypes,
  isFacialDeviceType,
} from '../../../../constants';
import { useCurrentLoggedContext, useRemoteConfig } from '../../../../hooks';
import KeepOpenDoorReasonModal from './Modals/ReasonModal';

const OptionLabel = ({ t, device, property }) => (
  <Flex alignItems="flex-start" flexDirection="column">
    <Text>{t('make-call')}</Text>
    <Text fontSize="10px" color="secondary600">
      {device?.serializedParams &&
      JSON.parse(device?.serializedParams)?.[property]
        ? `${t('extension')} ${
            JSON.parse(device?.serializedParams)?.[property]
          }`
        : t('no-extension')}
    </Text>
  </Flex>
);
OptionLabel.propTypes = {
  t: propTypes.func.isRequired,
  device: propTypes.object.isRequired,
  property: propTypes.string.isRequired,
};

const DeviceItem = ({
  device,
  event,
  placeName,
  handleDevice,
  handleClickToCall,
  disabledActions,
  deviceStatus,
  enableCommand,
  t,
}) => {
  const [hasDeviceStatus, setHasDeviceStatus] = useState(null);
  const [menuOptions, setMenuOptions] = useState(null);
  const [
    openReasonKeepOpenDoorModal,
    toggleReasonKeepOpenDoorModal,
  ] = useToggle(false);

  const { loggedContext } = useCurrentLoggedContext();

  const keepOpenDoorReason = useRemoteConfig({
    path: 'attendance.keepOpenReason',
    partner: Number(loggedContext?.partner?.personContextId),
    condominium: event?.condominium?.personContext?.id,
    user: Number(loggedContext?.personContextId),
  });

  const isFacialDevice =
    device?.deviceTypeId === deviceIdTypes.facial ||
    device?.deviceTypeId === deviceIdTypes.facialIntelbras;

  const {
    isVehicular,
    isPedestrian,
    isIntercom,
    isIntercomK2,
    isFacialStandAlone,
  } = useMemo(
    () => ({
      isVehicular:
        device.doorId &&
        !JSON.parse(device?.serializedParams)?.onlyAudio &&
        (device.deviceTypeId === deviceIdTypes.vehicular ||
          device.deviceTypeId === deviceIdTypes.accessVehicularV2 ||
          device.deviceTypeId === deviceIdTypes.accessVehicular),
      isPedestrian:
        device.doorId &&
        !JSON.parse(device?.serializedParams)?.onlyAudio &&
        (device.deviceTypeId === deviceIdTypes.kvoice ||
          device.deviceTypeId === deviceIdTypes.access ||
          device.deviceTypeId === deviceIdTypes.indoor ||
          device.deviceTypeId === deviceIdTypes.kvoiceT ||
          (isFacialDevice &&
            device?.parentDeviceId === device?.id &&
            !JSON.parse(device?.serializedParams)?.notAllowOpening)),
      isIntercom:
        device?.serializedParams &&
        (JSON.parse(device?.serializedParams)?.onlyAudio ||
          !device.doorId ||
          JSON.parse(device?.serializedParams)?.extension),
      isIntercomK2:
        device?.deviceTypeId === deviceIdTypes.interphone &&
        device?.serializedParams &&
        JSON.parse(device?.serializedParams)?.fullExtension,
      isFacialStandAlone:
        isFacialDeviceType(device) && device?.parentDeviceId === device?.id,
    }),
    [device],
  );

  const handleSendReason = data => {
    handleDevice(DEVICE_ACTIONS.keep_opened, device, keepOpenDoorReason, data);
    toggleReasonKeepOpenDoorModal();
  };

  useEffect(() => {
    const options = [];

    if (isPedestrian) {
      options.push(
        {
          label: t('details.cameras.commands.close'),
          onClick: () => {
            return handleDevice(DEVICE_ACTIONS.close, device);
          },
        },
        {
          label: t('details.cameras.commands.keep-open'),
          onClick: () => {
            return keepOpenDoorReason
              ? toggleReasonKeepOpenDoorModal()
              : handleDevice(
                  DEVICE_ACTIONS.keep_opened,
                  device,
                  keepOpenDoorReason,
                );
          },
        },
      );

      if (enableCommand) {
        options.splice(1, 0, {
          label: t('details.cameras.commands.keep-open-cleaning'),
          onClick: () => {
            handleDevice(DEVICE_ACTIONS.keep_opened_cleaning, device);
          },
        });
      }
    }
    if (isVehicular) {
      options.push(
        {
          label: t('details.cameras.commands.close'),
          onClick: () => {
            return handleDevice(DEVICE_ACTIONS.close, device);
          },
        },
        {
          label: t('details.cameras.commands.keep-open'),
          onClick: () => {
            return keepOpenDoorReason
              ? toggleReasonKeepOpenDoorModal()
              : handleDevice(
                  DEVICE_ACTIONS.keep_opened,
                  device,
                  keepOpenDoorReason,
                );
          },
        },
      );

      if (enableCommand) {
        options.splice(1, 0, {
          label: t('details.cameras.commands.keep-open-truck'),
          onClick: () => handleDevice(DEVICE_ACTIONS.keep_opened_truck, device),
        });
      }
    }
    if (isIntercom) {
      options.push({
        props: {
          icon: <FiVolume2 />,
          expandable: true,
        },
        label: <OptionLabel t={t} device={device} property="extension" />,
        onClick:
          device?.serializedParams &&
          JSON.parse(device?.serializedParams)?.extension
            ? () =>
                handleClickToCall({
                  destination: JSON.parse(device?.serializedParams)?.extension,
                  destinationKind: destinationTypes.intercom,
                })
            : null,
      });
    }
    if (isIntercomK2) {
      options.push({
        props: {
          icon: <FiVolume2 />,
          expandable: true,
        },
        label: <OptionLabel t={t} device={device} property="fullExtension" />,
        onClick: () =>
          handleClickToCall({
            destination: JSON.parse(device?.serializedParams)?.fullExtension,
            destinationKind: destinationTypes.intercomGeneric,
          }),
      });
    }

    setMenuOptions(options);
  }, [device]);

  useEffect(() => {
    setHasDeviceStatus(
      deviceStatus?.find(
        x =>
          x.serialNumber === device.serialNumber &&
          x.messageData.doorId === device.doorId,
      ),
    );
  }, [device, deviceStatus]);

  const hasOpenCommand = useMemo(() => {
    if (device?.deviceTypeId === deviceIdTypes.interphone)
      return !!JSON.parse(device?.serializedParams)?.urlToOpen;

    if (isFacialStandAlone)
      return !JSON.parse(device?.serializedParams)?.notAllowOpening;

    if (device?.deviceTypeId === deviceIdTypes.alarmCentralIntelbras || device?.deviceTypeId === deviceIdTypes.alarmCenterJfl) return false;

    return !!device?.doorId;
  }, [device]);

  return (
    <>
      {openReasonKeepOpenDoorModal && (
        <KeepOpenDoorReasonModal
          onToggle={toggleReasonKeepOpenDoorModal}
          open={openReasonKeepOpenDoorModal}
          sendReason={handleSendReason}
        />
      )}
      <CommandsDevice
        t={t}
        placeName={placeName}
        device={device}
        primaryCommand={
          hasOpenCommand
            ? {
                label: t('details.cameras.commands.open'),
                onClick: () => handleDevice(DEVICE_ACTIONS.open, device),
                icon: <FiUnlock />,
              }
            : null
        }
        doorStatus={
          (device?.doorId && hasDeviceStatus?.messageData?.doorStatus) ||
          device?.doorStatus
        }
        doorKeepOpenedReason={
          hasDeviceStatus?.messageData?.keepOpenedReason ||
          device?.keepOpenedReason
        }
        secondaryCommands={menuOptions}
        disabled={disabledActions}
        icon={
          device?.doorId ? (
            <RiDoorClosedLine size={28} />
          ) : (
            <FiVolume2 size={28} />
          )
        }
      />
    </>
  );
};

export default DeviceItem;

DeviceItem.propTypes = {
  t: propTypes.func.isRequired,
  device: propTypes.object.isRequired,
  event: propTypes.object.isRequired,
  handleDevice: propTypes.func.isRequired,
  handleClickToCall: propTypes.func.isRequired,
  placeName: propTypes.string,
  disabledActions: propTypes.bool,
  deviceStatus: propTypes.array.isRequired,
  enableCommand: propTypes.bool.isRequired,
};

DeviceItem.defaultProps = {
  placeName: null,
  disabledActions: false,
};
