import { useState, useMemo, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { apolloErrorHandler, removeProperties } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useFormikContext, useFormik } from 'formik';
import {
  MdDeleteOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineModeEditOutline,
  MdLockOutline,
  MdLockOpen,
} from 'react-icons/md';
import { Button, Loader, Text, Flex, KiperButton, Tooltip } from '@kiper/ui';
import { dweller as dwellerGql } from '@kiper/monitoring-graphql';
import { ThemeContext } from 'styled-components';
import { formatAccess } from '../helpers';
import ExpectedGuest from '../ExpectedGuest/ExpectedGuest';
import {
  AccessRestrictionsForm,
  AccessNode,
  AccessibilityTime,
  Profile,
  AccessProfiles,
  CallPriority,
  Observation,
  BookingTime,
  ContactOrder,
  ContactSchedule,
  MobileFunctionalities,
} from './components';
import {
  useUserRemoteConfig,
  useCurrentLoggedContext,
  useViewRules,
} from '../../../../hooks';
import { userProfile } from '../../../../constants';
import { accessDataSchema } from './schemas';
import { useBlockAccess } from './hooks';
import { Card, LoaderWrapper } from '../styles';

export default function AccessData({
  onChange,
  onRemove,
  access,
  isEdition,
  indexAccesses,
  formRef,
  user,
}) {
  const [t] = useTranslation('user');
  const [accordionOpened, toggleAcordion] = useState(false);
  const [hovered, setHovered] = useState(false);
  const { toast, swal } = useSwal();
  const isCreation = !access.id;
  const [editMode, setEditMode] = useState(false);
  const theme = useContext(ThemeContext);
  const { values: fatherValues, touched: fatherTouched } = useFormikContext();
  const { permission } = useViewRules({ path: 'users' });
  const { loggedContext } = useCurrentLoggedContext();

  const is = (fieldName, profile, state) =>
    profile &&
    permission &&
    permission.applicationFeature &&
    permission.applicationFeature.params.manageDwellerProfiles[profile] &&
    permission.applicationFeature.params.manageDwellerProfiles[profile][
      fieldName
    ] &&
    permission.applicationFeature.params.manageDwellerProfiles[profile][
      fieldName
    ][state];

  const isFieldVisible = (fieldName, value) => is(fieldName, value, 'visible');

  const isFieldRequired = (fieldName, value) =>
    is(fieldName, value, 'required');

  const onCompletedInsert = ({ dwellerAccessDataCreate }) => {
    onChange(dwellerAccessDataCreate);
    setValues(dwellerAccessDataCreate); //eslint-disable-line
    setEditMode(false);
  };

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const [update, { loading: loadingUpdate }] = useMutation(
    dwellerGql.detail.mutations.updateAccess,
    {
      // eslint-disable-next-line no-use-before-define
      onCompleted: onCompletedUpdate,
      onError,
    },
  );

  const [create, { loading: loadingCreate }] = useMutation(
    dwellerGql.detail.mutations.insertAccess,
    {
      onCompleted: onCompletedInsert,
      onError,
    },
  );

  const [remove, { loading: isRemoving }] = useMutation(
    dwellerGql.detail.mutations.deleteAccess,
    {
      onCompleted: () => {
        toast.fire({
          icon: 'success',
          title: t('put.access-data.modal.delete.success'),
        });
        onRemove();
      },
      onError,
    },
  );

  const onSubmit = ({ id, ...values }) => {
    // eslint-disable-next-line no-use-before-define
    if (!isValid) return null;

    const accessDataObject = formatAccess(values, isFieldVisible);

    if (id) {
      const accessData = removeProperties(accessDataObject, [
        '__typename',
        'fieldName',
      ]);

      return update({
        variables: {
          accessData,
          oldPersonContextId: Number(id),
        },
      });
    }

    return create({
      variables: {
        accessData: accessDataObject,
        personId: Number(fatherValues.personId),
      },
    });
  };

  const formikBag = useFormik({
    validationSchema: accessDataSchema({ isFieldRequired, t }),
    initialValues: { ...access },
    onSubmit,
  });

  const {
    values,
    handleSubmit,
    setValues,
    errors,
    isValid,
    isValidating,
    isSubmitting,
    resetForm,
  } = formikBag;

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting)
      toast.fire({ title: t('common:feedback.invalid-form'), icon: 'error' });
  }, [isValid, isValidating, isSubmitting]);

  const handleUpdateBlockedAccess = ({ ...updatedValues }) => {
    const updatedBlockAccessStatus = { ...values, ...updatedValues };
    onChange({ ...updatedBlockAccessStatus });
    setValues({
      ...updatedBlockAccessStatus,
    });
  };

  const { handleBlockAppAccessSubmit } = useBlockAccess(
    { user },
    submitValues => handleUpdateBlockedAccess({ ...submitValues }),
  );

  function onCompletedUpdate({ dwellerAccessDataUpdate }) {
    onChange({
      ...dwellerAccessDataUpdate,
    });
    setValues({
      ...dwellerAccessDataUpdate,
    }); //eslint-disable-line
    setEditMode(false);
  }

  // eslint-disable-next-line no-param-reassign
  formRef.current = setValues;

  const isLoading = useMemo(() => loadingCreate || loadingUpdate, [
    loadingCreate,
    loadingUpdate,
  ]);

  const showActions = useMemo(
    () => (!isCreation && editMode) || (isCreation && isEdition),
    [isCreation, editMode, isEdition],
  );

  const getCondominiumValues = (target, property) => {
    if (values.condominium.value === target.value)
      return { ...values, [property]: target };

    return {
      ...values,
      unityGroup: '',
      unity: '',
      condominium: target,
      accessProfiles: [''],
    };
  };

  const getProfileValues = target => {
    return {
      ...values,
      profile: target,
    };
  };

  const getUnityValues = target => {
    const unityValues = {
      ...values,
      unity: target.targetUnity,
      unityGroup: target.targetUnityGroup,
    };

    if (!target.targetUnity.defaultAccessProfile) return unityValues;

    return {
      ...unityValues,
      accessProfiles: [target.targetUnity.defaultAccessProfile],
    };
  };

  const handleChange = (property, target) => {
    const handlers = {
      condominium: () => getCondominiumValues(target, property),
      unity: () => getUnityValues(target),
      profile: () => getProfileValues(target),
      default: () => ({ ...values, [property]: target }),
    };

    const handler = handlers[property] || handlers.default;
    const modifiedAccess = handler();
    setValues(modifiedAccess);

    if (!values.id) onChange(modifiedAccess);
  };

  const handleEditMode = () => setEditMode(x => !x);

  const handleDelete = async () => {
    if (isCreation) return onRemove();

    return remove({
      variables: {
        personContextId: Number(values.id),
      },
    });
  };

  const showRemoveAlert = () => {
    swal.fire({
      title: t('put.access-data.modal.delete.title'),
      text: t('put.access-data.modal.delete.text'),
      cancelButtonText: t('put.access-data.modal.delete.cancel'),
      confirmButtonText: t('put.access-data.modal.delete.confirm'),
      showLoaderOnConfirm: true,
      preConfirm: handleDelete,
      allowOutsideClick: () => !swal.isLoading(),
    });
  };

  const handleCancel = () => {
    resetForm();
    handleEditMode();
  };

  const { showExpectedGuest, showContactOrder } = useUserRemoteConfig(
    loggedContext,
  );

  const handleBlockAccess = e => {
    e.stopPropagation();
    handleBlockAppAccessSubmit({
      id: values.id,
      isBlocked: !values.isBlocked,
      blockedReason: values.blockedReason,
    });
  };

  const {
    showActionsButtons,
    showDeleteActionButton,
    showContentAccordion,
  } = useMemo(
    () => ({
      showActionsButtons: hovered || (accordionOpened && !isCreation),
      showDeleteActionButton: hovered || accordionOpened,
      showContentAccordion: accordionOpened || editMode || isCreation,
    }),
    [accordionOpened, hovered, isCreation, editMode],
  );

  return (
    <>
      {isRemoving ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Card
          $isBlocked={values.isBlocked}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Flex
            p="16px"
            onClick={() =>
              !editMode && !isCreation && toggleAcordion(!accordionOpened)
            }
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center" flex={1}>
              <Flex flexDirection="column">
                <Text mb="4px" fontSize="12px" color="secondary500">
                  {t('put.access-data.condominium')}
                </Text>
                <Text fontSize="14px">{values?.condominium?.label || '-'}</Text>
              </Flex>
            </Flex>

            <Flex flex={1}>
              <Flex mr="10px" flexDirection="column">
                <Text mb="4px" fontSize="12px" color="secondary500">
                  {t('put.access-data.unity-group')}
                </Text>
                <Text fontSize="14px">{values?.unityGroup?.label || '-'}</Text>
              </Flex>
              <Flex mr="10px" flexDirection="column">
                <Text mb="4px" fontSize="12px" color="secondary500">
                  {t('put.access-data.unity')}
                </Text>
                <Text fontSize="14px">{values?.unity?.label || '-'}</Text>
              </Flex>
            </Flex>

            <Flex justifyContent="end" width="100px" gridGap="4px">
              {showActionsButtons && (
                <>
                  <>
                    <Tooltip
                      placement="top"
                      target={`access-block-button-${values.id}`}
                    >
                      {values.isBlocked
                        ? t(
                            'put.access-data.block-user-access.access-unblock-button',
                          )
                        : t(
                            'put.access-data.block-user-access.access-block-button',
                          )}
                    </Tooltip>
                    <KiperButton
                      id={`access-block-button-${values.id}`}
                      type="button"
                      size="md"
                      onClick={handleBlockAccess}
                      color={values.isBlocked ? 'primary' : 'danger'}
                      icon={
                        values.isBlocked ? (
                          <MdLockOpen size={20} />
                        ) : (
                          <MdLockOutline size={20} />
                        )
                      }
                    />
                  </>
                  <KiperButton
                    type="button"
                    disabled={editMode || isCreation || values.isBlocked}
                    size="md"
                    onClick={() => {
                      handleEditMode();
                      toggleAcordion(true);
                    }}
                    icon={<MdOutlineModeEditOutline size={20} />}
                  />
                </>
              )}
              {showDeleteActionButton && (
                <KiperButton
                  type="button"
                  size="md"
                  color="danger"
                  onClick={showRemoveAlert}
                  icon={<MdDeleteOutline size={20} />}
                />
              )}

              <KiperButton
                type="button"
                disabled={editMode || isCreation}
                size="md"
                color="neutral"
                variant="text"
                onClick={() => toggleAcordion(!accordionOpened)}
                icon={
                  accordionOpened ? (
                    <MdKeyboardArrowUp fontSize={20} />
                  ) : (
                    <MdKeyboardArrowDown fontSize={20} />
                  )
                }
              />
            </Flex>
          </Flex>
          {showContentAccordion && (
            <Flex
              borderTop={`1px solid ${theme.colors.secondary300}`}
              flexDirection="column"
              py="15px"
              mx="16px"
            >
              <Profile
                isEditable={isCreation || editMode}
                values={values}
                indexAccesses={indexAccesses}
                handleChange={handleChange}
              />

              <AccessNode
                isCreation={isCreation}
                isEditable={isCreation || editMode}
                handleChange={handleChange}
                values={values}
                touched={fatherTouched?.accesses?.[indexAccesses]}
                errors={errors}
                isFieldVisible={isFieldVisible}
              />

              {isFieldVisible('accessProfile', values?.profile?.value) && (
                <AccessProfiles
                  values={values}
                  touched={fatherTouched?.accesses?.[indexAccesses]}
                  errors={errors}
                  handleChange={handleChange}
                  isCreation={isCreation}
                  editMode={editMode}
                  isFieldVisible={isFieldVisible}
                />
              )}

              {isFieldVisible('allowedAccesses', values?.profile?.value) && (
                <AccessRestrictionsForm
                  values={values?.allowedAccesses ?? []}
                  handleChange={handleChange}
                  errors={errors}
                  isEditable={isCreation || editMode}
                />
              )}

              {values?.profile?.value === userProfile.janitor && (
                <ContactSchedule
                  values={values?.contactSchedule ?? []}
                  handleChange={handleChange}
                  errors={errors}
                  isEditable={isCreation || editMode}
                />
              )}

              {isFieldVisible('bookingTime', values?.profile?.value) && (
                <BookingTime
                  values={values.bookingTime ?? {}}
                  handleChange={handleChange}
                  isEditable={isCreation || editMode}
                />
              )}

              {isFieldVisible('features', values?.profile?.value) && (
                <MobileFunctionalities
                  values={values}
                  handleChange={handleChange}
                  indexAccesses={indexAccesses}
                  isEditable={isCreation || editMode}
                />
              )}

              {isFieldVisible('accessibilityTime', values?.profile?.value) && (
                <AccessibilityTime
                  values={values}
                  handleChange={handleChange}
                  isEditable={isCreation || editMode}
                />
              )}

              {isFieldVisible('callPriority', values?.profile?.value) && (
                <CallPriority
                  values={values}
                  handleChange={handleChange}
                  isEditable={isCreation || editMode}
                />
              )}
              {isFieldVisible('observation', values?.profile?.value) && (
                <Observation
                  values={values}
                  handleChange={handleChange}
                  isEditable={isCreation || editMode}
                />
              )}

              {isFieldVisible('contactOrder', values?.profile?.value) &&
                showContactOrder && (
                  <ContactOrder
                    values={values}
                    user={user}
                    handleChange={handleChange}
                    isEditable={isCreation || editMode}
                  />
                )}

              {isEdition && !isCreation && showExpectedGuest && (
                <ExpectedGuest
                  isBlocked={values.isBlocked}
                  user={user}
                  personContextId={Number(access?.id)}
                />
              )}

              {showActions && (
                <Flex justifyContent="flex-end" margin="15px 0 0 0">
                  {!isCreation && (
                    <Button
                      type="button"
                      outline
                      className="mr-3"
                      onClick={() => {
                        handleCancel();
                        toggleAcordion(false);
                      }}
                    >
                      {t('put.cancel')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || isLoading}
                    loading={isLoading}
                    color="primary"
                    onClick={handleSubmit}
                  >
                    {isLoading ? t('buttons:saving') : t('buttons:save')}
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </Card>
      )}
    </>
  );
}

AccessData.propTypes = {
  access: propTypes.any,
  indexAccesses: propTypes.any,
  user: propTypes.any,
  isEdition: propTypes.bool.isRequired,
  formRef: propTypes.object,
  onChange: propTypes.func.isRequired,
  onRemove: propTypes.func.isRequired,
};

AccessData.defaultProps = {
  formRef: () => {},
  access: {},
  indexAccesses: null,
  user: {},
};
