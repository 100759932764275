import { Flex } from '@kiper/ui';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 16px;
  height: 100%;
  width: 424px;
  min-width: 424px;
  max-width: 424px;
  flex-direction: column;

  gap: 16px;

  @media screen and (min-width: 1920px) {
    width: 596px;
    min-width: 596px;
    max-width: 596px;
    gap: 32px;
  }
`;

export const LoadingContainer = styled(Flex)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
