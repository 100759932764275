import { useGuidedAttendance } from '../../../../hooks';
import useMutations from './useMutations';

const useNextAction = ({ eventId }) => {
  const { handleNextAction } = useGuidedAttendance();
  const { handleInsertStepHistory, handleCreateTriage } = useMutations();

  const nextAction = async stepData => {
    Promise.all([
      handleCreateTriage(stepData, eventId),
      handleInsertStepHistory(stepData, eventId),
    ]);

    return handleNextAction({
      values: stepData,
      fieldValue: 'stepAttendanceGetReason',
    });
  };

  return { nextAction };
};

export default useNextAction;
