import { Flex, Text as text } from '@kiper/ui';
import styled from 'styled-components';

export const YesOrNoContainer = styled(Flex)`
  gap: 4px;
  background-color: ${props => props.theme.colors.warning50};
  padding: 4px 8px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled(text)`
  font-size: 12px;
  font-weight: 800;
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.highBlack};
`;

export const Badge = styled.div`
  padding: 2px 16px;
  border-radius: 16px;
  background-color: ${props => props.theme.colors[props.color]};
`;
