import useReduceRemoteConfigFlags from './useReduceRemoteConfigFlags';

const CondominiumPaths = {
  RESYNC_COND: 'condominium.resyncCond',
  ATTENDANCE_SETTINGS: 'condominium.attendanceSettings',
  APPLICATION_PARAMETERS: 'condominium.usersNotifications',
  ACTIVITY_MANAGEMENT: 'condominium.activityManagement',
  SHOW_NEW_MOSAIC_CONFIG: 'condominium.showNewMosaicConfig',
  ACTIVATE_CONDOMINIUM: 'condominium.activateCondominium',
  INVITE_DWELLERS_URL: 'condominium.inviteDwellersUrl',
  VIRTUAL_PEDESTRIAN: 'condominium.isVirtualPedestrianEnabled',
  AUTHORIZATION_MENU: 'condominium.isAuthorizationEnabled',
  DRIVER_FEATURE: 'condominium.isDriverFeatureEnabled',
  DELIVERY_FEATURE: 'condominium.isDeliveryFeatureEnabled',
  ORDER_PICKUP: 'condominium.orderPickupStatus',
  VIDEO_CALL_FEATURE: 'condominium.isVideoCallFeatureEnabled',
  SHOW_CAMERA_IN_ALARM_CENTER: 'condominium.showCameraInAlarmCenter',
  SHOW_INDOOR: 'condominium.indoor',
  SHOW_EXPORTATIONS: 'condominium.showExportations',
  SHOW_TOGGLE_FOR_GUEST_ONLY: 'condominium.forGuestsOnlyToggle',
};

export const useCondominiumRemoteConfig = (loggedContext, condominium) => {
  const remoteConfig = useReduceRemoteConfigFlags(
    loggedContext,
    CondominiumPaths,
    condominium,
  );

  const showAttendanceSettings =
    remoteConfig[CondominiumPaths.ATTENDANCE_SETTINGS];
  const showApplicationParameters =
    remoteConfig[CondominiumPaths.APPLICATION_PARAMETERS];
  const showActivityManagement =
    remoteConfig[CondominiumPaths.ACTIVITY_MANAGEMENT];
  const showResyncCond = remoteConfig[CondominiumPaths.RESYNC_COND];
  const showNewMosaicConfig =
    remoteConfig[CondominiumPaths.SHOW_NEW_MOSAIC_CONFIG];
  const activateCondominiumFeatureFlag =
    remoteConfig[CondominiumPaths.ACTIVATE_CONDOMINIUM];
  const showCondominiumInviteDwellers =
    remoteConfig[CondominiumPaths.INVITE_DWELLERS_URL];
  const showVirtualPedestrian =
    remoteConfig[CondominiumPaths.VIRTUAL_PEDESTRIAN];
  const showAuthorizationMenu =
    remoteConfig[CondominiumPaths.AUTHORIZATION_MENU];
  const showDriverFeature = remoteConfig[CondominiumPaths.DRIVER_FEATURE];
  const showDeliveryFeature = remoteConfig[CondominiumPaths.DELIVERY_FEATURE];
  const showVideoCallFeature =
    remoteConfig[CondominiumPaths.VIDEO_CALL_FEATURE];
  const showIndoor = remoteConfig[CondominiumPaths.SHOW_INDOOR];
  const showCameraInAlarmCenter =
    remoteConfig[CondominiumPaths.SHOW_CAMERA_IN_ALARM_CENTER];
  const orderPickupStatus = remoteConfig[CondominiumPaths.ORDER_PICKUP];
  const showExportations = remoteConfig[CondominiumPaths.SHOW_EXPORTATIONS];
  const showToogleForGuestsOnly =
    remoteConfig[CondominiumPaths.SHOW_TOGGLE_FOR_GUEST_ONLY];

  return {
    showAttendanceSettings,
    showApplicationParameters,
    showActivityManagement,
    showResyncCond,
    showNewMosaicConfig,
    activateCondominiumFeatureFlag,
    showCondominiumInviteDwellers,
    showVirtualPedestrian,
    showAuthorizationMenu,
    showDriverFeature,
    showDeliveryFeature,
    showVideoCallFeature,
    showIndoor,
    showCameraInAlarmCenter,
    orderPickupStatus,
    showExportations,
    showToogleForGuestsOnly,
  };
};
