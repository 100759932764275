import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';
import { Select } from '@kiper/ui';
import {
  Row,
  Label,
  FormGroup,
  InputMask,
  Input,
  ErrorMessageComponent,
} from './styles';

const Document = ({
  editMode,
  documentTypes,
  allowedDocumentTypes,
  documentTypeId,
  documentValue,
  handleChange,
  validateByMask,
  index,
}) => {
  const [t] = useTranslation('user');

  const { errors, touched } = useFormikContext();

  const toSelectValue = document => {
    const { id, name } = document;

    if (!id || !name) return null;
    return {
      value: id,
      label: name,
    };
  };

  const { documentMask, documentName } = useMemo(() => {
    const { mask, name } =
      documentTypes.find(doc => doc?.id === documentTypeId) ?? {};
    return { documentMask: mask, documentName: name };
  }, [documentTypeId, documentTypes]);

  useEffect(() => {
    const maskLength = documentMask?.trim()?.replaceAll('.', '').length;
    const documentLength = documentValue?.trim()?.replaceAll('.', '')?.length;

    const isValid =
      !maskLength || !documentLength || maskLength === documentLength;

    validateByMask(isValid);
  }, [documentValue]);

  return (
    <Row>
      <FormGroup>
        <Label info block>
          {t('put.document-id')}
        </Label>
        {!editMode ? (
          <Label>{documentName}</Label>
        ) : (
          <>
            <Select
              name="documentTypeId"
              options={documentTypes.map(toSelectValue)}
              value={toSelectValue(
                documentTypes.find(doc => doc?.id === documentTypeId) ?? {},
              )}
              onChange={({ value }) => {
                handleChange({ attr: 'documentTypeId', value });
              }}
              placeholder={t('put.placeholders.type-document')}
              isOptionDisabled={option =>
                !allowedDocumentTypes.some(
                  allowed => allowed.id === option.value,
                )
              }
              hideSelectedOptions
              invalid={
                !!errors?.documents?.[index]?.documentTypeId &&
                touched?.documents?.[index]?.documentTypeId
              }
            />
            <ErrorMessage
              component={ErrorMessageComponent}
              name={`documents[${index}].documentTypeId`}
            />
          </>
        )}
      </FormGroup>
      <FormGroup>
        <Label info block>
          {t('put.document-number')}
        </Label>
        {!editMode ? (
          <Label>{documentValue}</Label>
        ) : (
          <>
            {documentMask ? (
              <InputMask
                name="value"
                mask={documentMask}
                value={documentValue}
                onChange={value => handleChange({ attr: 'value', value })}
                placeholder={t('put.placeholders.document')}
                autoComplete="off"
                invalid={
                  !!errors?.documents?.[index]?.value &&
                  touched?.documents?.[index]?.value
                }
              />
            ) : (
              <Input
                placeholder={t('put.placeholders.document')}
                value={documentValue}
                type="text"
                name="value"
                onChange={(id, value) => handleChange({ attr: 'value', value })}
                maxLength="18"
                autoComplete="off"
                invalid={
                  !!errors?.documents?.[index]?.value &&
                  touched?.documents?.[index]?.value
                }
              />
            )}
            <ErrorMessage
              component={ErrorMessageComponent}
              name={`documents[${index}].value`}
            />
          </>
        )}
      </FormGroup>
    </Row>
  );
};

const documentTypePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  origin: PropTypes.string,
  mask: PropTypes.string,
});

Document.propTypes = {
  editMode: PropTypes.bool.isRequired,
  documentTypes: PropTypes.arrayOf(documentTypePropType).isRequired,
  allowedDocumentTypes: PropTypes.arrayOf(documentTypePropType).isRequired,
  documentTypeId: PropTypes.string.isRequired,
  documentValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  validateByMask: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default Document;
